











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class GMap extends Vue {
  @Prop({ required: true })
  public height!: string;

  @Prop()
  public place!: string;

  mapSrc() {
    const place = encodeURIComponent(this.place);
    const mapKey = 'AIzaSyA7GTX4z6yde19-A6tZ6ColrWPF-1gfc1E';

    return `https://www.google.com/maps/embed/v1/place?q=${place}&key=${mapKey}`;
  }
}
