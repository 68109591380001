








































import { BrowserStorageService, ServiceManager, ServiceType, API, Config } from 'client-website-ts-library/services';
import { Component, Vue } from 'vue-property-decorator';
import { Office } from 'client-website-ts-library/types';

import Navigation from './Navigation.vue';
import NavigationSide from './NavigationSide.vue';
import DropDownList from '../UI/DropDownList.vue';

@Component({
  components: {
    Navigation,
    DropDownList,
    NavigationSide,
  },
})
export default class Header extends Vue {
  public selectedOffice: string | null = null;

  public offices: Office[] = [];

  public isStuck = false;

  public mobileNavActive = false;

  /* Configuration Options */
  public gradient = false;

  public fixed = false;

  public stickyNav = true;

  public eventListenerOpts: AddEventListenerOptions | EventListenerOptions = { passive: true };

  get month(): number {
    return new Date().getMonth() + 1;
  }

  mounted() {
    this.checkScroll();

    this.$router.afterEach(() => {
      this.mobileNavActive = false;
    });

    API.Franchises.GetOffices(Config.Website.Settings!.WebsiteId).then((offices) => {
      this.offices = offices;
    });
  }

  selectOffice(): void {
    const browserStorage = ServiceManager.Require<BrowserStorageService>(ServiceType.BrowserStorage);

    browserStorage.setItem('ep_selected_office', this.selectedOffice);

    const office = this.offices.find((tmp) => tmp.Id === this.selectedOffice);

    if (office != null) {
      this.$router.push(`/offices/${office.OfficeSlug}`);
    }
  }

  checkScroll(): void {
    this.isStuck = window.scrollY > 0;
  }

  created() {
    window.addEventListener('scroll', this.checkScroll, this.eventListenerOpts);
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.checkScroll, this.eventListenerOpts);
  }
}
