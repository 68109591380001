import { API } from 'client-website-ts-library/services';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
  },
  // buy
  {
    path: '/listings/residential-sale',
    name: 'Residential Sale',
    component: () => import('../views/ResidentialForSale.vue'),
  },
  {
    path: '/listings/commercial-sale',
    name: 'Commercial Sale',
    component: () => import('../views/CommercialForSale.vue'),
  },
  {
    path: '/inspection-times',
    name: 'Inspection Times',
    component: () => import('../views/InspectionTimes.vue'),
  },
  {
    path: '/investors-support',
    name: 'Investor Support',
    component: () => import('../views/InvestorsSupport.vue'),
  },
  // sell
  {
    path: '/why-choose-us-sales',
    name: 'Why Choose Us',
    component: () => import('../views/WhyChooseUsSales.vue'),
  },
  {
    path: '/forms/sales-appraisal',
    name: 'Sales Appraisal',
    component: () => import('../views/SalesAppraisal.vue'),
  },
  {
    path: '/listings/recent-sales',
    name: 'Recent Sales',
    component: () => import('../views/ListingsSold.vue'),
  },
  // rent
  {
    path: '/listings/residential-rental',
    name: 'Residential Rental',
    component: () => import('../views/ResidentialForRent.vue'),
  },
  {
    path: '/listings/commercial-rental',
    name: 'Commercial Rental',
    component: () => import('../views/CommercialForRent.vue'),
  },
  {
    path: '/forms/rental-appraisal',
    name: 'Rental Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  {
    path: '/tenant-resources',
    name: 'Tenant Resources',
    component: () => import('../views/TenantResources.vue'),
  },
  {
    path: '/referral-program',
    name: 'Referral Program',
    component: () => import('../views/ReferralProgram.vue'),
  },
  {
    path: '/forms/request-maintenance',
    name: 'Request Maintenance',
    component: () => import('../views/MaintenanceRequest.vue'),
  },
  {
    path: '/tenancy-application',
    name: 'Tenancy Application',
    component: () => import('../views/TenancyApplicationForm.vue'),
  },
  {
    path: '/tenant-faq',
    name: 'Tenant F.A.Q.',
    component: () => import('../views/TenantFAQ.vue'),
  },
  {
    path: '/tenants-guide',
    name: 'Tenants Guide To Renting',
    component: () => import('../views/TenantsGuideToRenting.vue'),
  },
  {
    path: '/vacating-a-rental-property',
    name: 'Vacating A Rental Property',
    component: () => import('../views/VacatingARentalProperty.vue'),
  },
  // manage
  {
    path: '/why-choose-us-manage',
    name: 'Why Choose Us Manage',
    component: () => import('../views/WhyChooseUsManage.vue'),
  },
  {
    path: '/our-fees',
    name: 'Our Fees',
    component: () => import('../views/OurFees.vue'),
  },
  {
    path: '/forms/rental-appraisal',
    name: 'Rental Appraisal',
    component: () => import('../views/RentalAppraisal.vue'),
  },
  {
    path: '/forms/appointment-or-reappointment-of-agent',
    name: 'Rental Appraisal',
    component: () => import('../views/Form6.vue'),
  },
  {
    path: '/service-guarantee',
    name: 'Service Guarantee',
    component: () => import('../views/ServiceGuarantee.vue'),
  },
  {
    path: '/we-pay-the-rent',
    name: 'We Pay The Rent',
    component: () => import('../views/WePayYourRent.vue'),
  },
  {
    path: '/90-day-free-trial',
    name: '90 Day Free Trial',
    component: () => import('../views/NinetyDayFreeTrial.vue'),
  },
  // about
  {
    path: '/our-culture',
    name: 'Who We Are',
    component: () => import('../views/OurCulture.vue'),
  },
  {
    path: '/our-team',
    name: 'Our Team',
    component: () => import('../views/OurTeam.vue'),
  },
  {
    path: '/our-ethics',
    name: 'Our Ethics',
    component: () => import('../views/OurEthics.vue'),
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import('../views/Careers.vue'),
  },
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: () => import('../views/Contact.vue'),
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue'),
  },
  {
    path: '/people',
    name: 'Our People',
    component: () => import('../views/OurTeam.vue'),
  },
  {
    path: '/testimonials',
    name: 'Testimonials',
    component: () => import('../views/Testimonials.vue'),
  },
  {
    // Dynamic Pages
    path: '/listings/:id',
    name: 'Listing',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingDetails.vue'),
  },
  {
    // Dynamic Pages
    path: '/listings/:id/make-an-offer',
    name: 'Listing Make An Offer',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/ListingMakeAnOffer.vue'),
  },
  {
    path: '/blogs/:id',
    name: 'Blog Details',
    meta: {
      hide: true,
    },
    component: () => import('../views/BlogDetails.vue'),
  },
  {
    path: '/suburb-blogs/:id',
    name: 'Blog Suburb Details',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/BlogSuburb.vue'),
  },
  {
    path: '/people/:id',
    name: 'Profile',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfile.vue'),
  },
  {
    path: '/people/:id/properties/sold',
    name: 'Profile Properties Sold',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListingsSold.vue'),
  },
  {
    path: '/people/:id/properties',
    name: 'Profile Properties',
    meta: {
      hide: true,
    },
    component: () => import('../views/AgentProfileListings.vue'),
  },
  {
    path: '/jamie',
    name: 'Jamie',
    meta: {
      hideAncillaries: true,
    },
    component: () => import('../views/Jamie.vue'),
  },
  {
    path: '/claudio',
    name: 'Claudio',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/Claudio.vue'),
  },
  {
    path: '/teameast',
    name: 'Team East',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/Teameast.vue'),
  },
  {
    path: '/teammichael',
    name: 'Team Michael',
    meta: {
      hide: true,
      hideAncillaries: true,
    },
    component: () => import('../views/Teammichael.vue'),
  },
  // CoreLogic Plugin
  {
    path: '/property-report',
    name: 'Property Report',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReport.vue'),
  },
  {
    path: '/property-report-preview',
    name: 'Property Report Preview',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReportPreview.vue'),
  },
  {
    path: '/property-report-digital-appraisal',
    name: 'Property Report Digital Appraisal',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReportDigitalAppraisal.vue'),
  },
  {
    path: '/rental-report',
    name: 'Rental Report',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReport.vue'),
  },
  {
    path: '/rental-report-preview',
    name: 'Rental Report Preview',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReportPreview.vue'),
  },
  {
    path: '/rental-report-digital-appraisal',
    name: 'Rental Report Digital Appraisal',
    meta: {
      hide: true,
    },
    component: () => import('../views/PropertyReportDigitalAppraisal.vue'),
  },
  {
    path: '/suburb-report',
    name: 'Suburb Report',
    meta: {
      hide: true,
    },
    component: () => import('../views/SuburbReport.vue'),
  },
  {
    path: '/suburb-report-detail',
    name: 'Suburb Report Detail',
    meta: {
      hide: true,
    },
    component: () => import('../views/SuburbReportDetail.vue'),
  },
  // Other
  {
    path: '/sitemap',
    name: 'Sitemap',
    meta: {
      hide: true,
    },
    component: () => import('../views/Sitemap.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import('../views/PrivacyPolicy.vue'),
  },
  {
    path: '/*',
    name: 'Not Found',
    meta: {
      hide: true,
      hideOfficeMap: true,
    },
    component: () => import('../views/NotFound.vue'),
    beforeEnter: (to, from, next) => {
      if (to.fullPath) {
        API.Slugs.Resolve(to.fullPath.split(' ').join('-'))
          .then((slug) => {
            switch (slug.Type) {
              case 'Listing':
                next({
                  path: `/listings/${slug.Slug}`,
                  name: slug.Slug,
                  replace: true,
                });
                break;
              case 'Profile':
                next({
                  path: `/people/${slug.Slug}`,
                  replace: true,
                });
                break;
              case 'Office':
                API.Offices.Get(slug.Id)
                  .then((office) => {
                    next({
                      path: `/offices/${office.OfficeSlug}`,
                      replace: true,
                    });
                  })
                  .catch(() => {
                    next();
                  });
                break;
              default:
                next();
                break;
            }
          })
          .catch(() => {
            next();
          });
      } else {
        next();
      }
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (from && from.path === to.path) return null;

    if (savedPosition) return savedPosition;

    return { x: 0, y: 0 };
  },
});

export default router;
