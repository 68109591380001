















// import { Office } from 'client-website-ts-library/types';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { API, Config } from 'client-website-ts-library/services';
import { Office } from 'client-website-ts-library/types';

import NavigationLink from './NavigationLink.vue';

@Component({
  components: {
    NavigationLink,
  },
})
export default class Header extends Vue {
  @Prop()
  private readonly mobileNavActive!: boolean;

  @Prop()
  private readonly isStuck!: boolean;

  private office: Office | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }
}
